
a{
    .highlight-header{
        text-align: center;
        background: $teal-200;
        transition: all .5s ease ;
        h2{
            margin: 0px;
            padding: 6vw 9vw 6vw 9vw;
            color: $indigo-700;
            font-size: 3vw;
        }
   
        &:hover{
           background: $teal-400;
        }
    }
    &:hover{
        text-decoration: none!important;

        h2{
            color: $indigo-600;
        }
    }
}
