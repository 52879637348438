.qrcodemode{
    display: none;
    &.active{
        display: flex;
    }
}
.qrcode-settings{
    display: none;
}
.qrcode-data{
    display: none;
}
.qrcode-result{
    svg{
        max-width: 100%!important;
    }
}