
.sep{
    border-bottom: 1px solid $bgDark;
    width: 100%;
    margin-bottom: 15px;
    opacity: .4;
}
.divhide{
    display: none;
}

.opacity-25{
    opacity: .25;
}

h2{
    margin-top: 2rem;
    margin-bottom: .5rem;
    color: var(--primary);
    font-size: 1.2rem;
}

h3{
    margin-top: 2rem;
    margin-bottom: .5rem;
    color: var(--primary);
    font-size: 1rem;
}